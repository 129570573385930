import Table from "../../../components/table/table";
import { services } from "../../../utility/checkRoleBasedPermission";
import Permission from "../../auth/permissions";
import MobileResponsive from '../orderAllocation/mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import { useSelector } from 'react-redux';
import { setCancels } from '../../../store/cancelsSlice';

const columns = [
	{
		field: 'cancelId',
		headerName: 'Cancel Id',
		flex: 0.6,
		headerClassName: 'super-app-theme--header',
	},
	{
		field: 'orderName',
		headerName: 'Order Name',
		flex: 0.8,
		headerClassName: 'super-app-theme--header',
	},
	{
		field: 'statusForCustomer',
		headerName: 'Status For Customer',
		flex: 1,
		headerClassName: 'super-app-theme--header',
	},
	{
		field: 'reason',
		headerName: 'Reason',
		flex: 2.5,
		headerClassName: 'super-app-theme--header'
	},
	{
		field: 'locationCode',
		headerName: 'Location Code',
		flex: 1,
		headerClassName: 'super-app-theme--header'
	},
	{
		field: 'cancelledBy',
		headerName: 'Cancelled By',
		flex: 0.8,
		headerClassName: 'super-app-theme--header'
	},
	{
		field: 'createdAt',
		headerName: 'Created At',
		flex: 1.7,
		headerClassName: 'super-app-theme--header'
	},
];


const Cancel = (props) => {
    const cancels = useSelector((state) => state?.cancels);

	return (
		<Permission service={services?.cancels} permission={"read"}>
			<Table
				columns={columns}
				dataPosition={"fetchedCancels"}
				endpoint={`/pgOrder/admin/cancels/`}
				rowId={'cancelId'}
				version="version-1"
				service={'cancelOrder'}
				tabName={'Cancels'}
				setData={setCancels}
				data={cancels}
				mobileResponsive={true}
				exportEndpoint={'/exports/fetchCancelOrders'}
				exportFileName={`Cancel Orders - ${new Date()}`}
			/>
		</Permission>
	);
};

export default Cancel;
