import React, { Fragment } from 'react';
import Chip from '@mui/material/Chip';
import { setDraftPurchaseOrders } from '../../../store/draftPurchaseOrdersSlice';
import { useSelector } from 'react-redux';
import Table from "../../../components/table/table";

const DraftPurchaseOrders = () => {
	const draftPurchaseOrders = useSelector((state) => state.draftPurchaseOrders);
	const columnVisibilityModel = { locationName: false }

	const StatusRenderCell = (row) => {
		return (
			row?.status === "Completed" ? (
				<Chip label={row?.status} />
			) : (
				<Chip style={{ backgroundColor: '#ffea88' }} label={row?.status} />
			)
		)
	}

	let columns = [
		{ 
			field: 'draftPurchaseOrderName', 
			filterable: true, 
			headerName: 'DRAFT ORDER', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true
		},
		{ 
			field: 'createdAt', 
			filterable: false, 
			headerName: 'DATE', 
			flex: 1.5, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'vendorName', 
			filterable: true, 
			headerName: 'VENDOR', 
			flex: 1.5, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'vendorName',
			tableName: 'DpoVendorDetails'
		},
		{ 
			field: 'locationCode', 
			filterable: true, 
			headerName: 'Location Code', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'locationCode',
			tableName: 'DpoLocationDetails'
		},
		{ 
			field: 'locationName', 
			filterable: true, 
			headerName: 'Location Name', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'locationName',
			tableName: 'DpoLocationDetails'
		},
		{
			field: 'status',
			headerName: 'STATUS',
			headerClassName: 'super-app-theme--header',
			headerAlign: 'center',
			align: 'center',
			filterable: true,
			sortable: true,
			flex: 1.1,
			renderCell: (params) => ( <StatusRenderCell {...params?.row} />),
			customRenderCell: StatusRenderCell,
			enumValues: [ "Draft", "Pending Approval", "Completed" ],
		},
		{ 
			field: 'totalAmount', 
			filterable: false, 
			valueFormatter: (params) => `₹${params.value}`, 
			headerName: 'TOTAL', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true 
		},
		{ 
			field: 'createdBy', 
			filterable: false, 
			headerName: 'CREATED BY', 
			flex: 1, 
			headerClassName: 'super-app-theme--header', 
			sortable: true,
			type: 'enum',
			columnName: 'createdBy',
			tableName: 'DraftPurchaseOrders'
		},
	];

	return (
		<Fragment>
			<Table
				columns={columns}
				dataPosition={"draftPurchaseOrders"}
				endpoint={`/purchaseOrders/draftPurchaseOrders`}
				rowId={'draftPurchaseOrderName'}
				checkBoxSelection={false}
				version="version-1"
				service={'draftPurchaseOrders'}
				tabName={'Draft Purchase Orders'}
				navigateOnRowClickEndpoint={`/draftPurchaseOrders`}
				setData={setDraftPurchaseOrders}
				data={draftPurchaseOrders}
				mobileResponsive={true}
				columnVisibilityModel={columnVisibilityModel}
				exportEndpoint={'/exports/fetchDraftPurchaseOrders'}
				exportFileName={`Draft Purchase Orders - ${new Date()}`}
				searchKey={'text'}
				clickable={true}
			/>
		</Fragment>
	);
};

export default DraftPurchaseOrders;
