import {
    DataGrid,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import React, { Fragment, useRef } from 'react';
import NewPagination from '../newPagination'
import { Box, Paper } from "@mui/material";
import axiosInstance from '../../utility/axios-instance';
import { useEffect, useState } from 'react';
import Classes from './css/table.module.css';
import { CheckRoleBasedPermission, permission, services } from '../../utility/checkRoleBasedPermission';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCheckBox } from '../../store/storeSelectedCheckbox';
import Spinner from '../spinner';
import moment from 'moment';
import { downloadCSV } from '../../utility/downloadCSV';
import CustomToolbar from './CustomToolbar';
import EnumInputValue from './EnumInputValue';
import TimeStampInputValue from './TimeStampInputValue';
import { getGridNumericOperators } from '@mui/x-data-grid';
import { useSearchParams } from 'react-router-dom';
import SnackBar from '../SnackBar';

function extFn(url) {
    const match = url.match(/\.[0-9a-z]+$/i);
    return match ? match[0].slice(1) : "";
}

function downFn(url, fileName) {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!pattern.test(url)) {
        alert( "Wrong URL Entered")
        return;
    }
    fetch(url)
        .then((res) => {
            if (!res.ok) {
                throw new Error("Network Problem");
            }
            return res.blob();
        })
        .then((file) => {
            const ex = extFn(url);
            let tUrl = URL.createObjectURL(file);
            const tmp1 = document.createElement("a");
            tmp1.href = tUrl;
            tmp1.download = `${fileName}.${ex}`;
            document.body.appendChild(tmp1);
            tmp1.click();
            URL.revokeObjectURL(tUrl);
            tmp1.remove();
        })
        .catch((error) => {
            console.log(error,'error')
        });
}

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

export default function DesktopResponsiveTable({
    endpoint, dataPosition, navigateOnRowClickEndpoint, columns, checkBoxSelection,
    rowSelectionEndpoint, rowId, reRender, version, columnVisibilityModel,
    initialFilterState, service, searchData, setData, data, tabName, type,
    extraToolBarButtons, isMovementWithinVisible, exportEndpoint, exportFileName, delayedExport, redirectUrlOnSnackBarClick,
    createdByFilter, date, setSelectedId, searchKey, isReactDispatch, filterName, flagged, locationCode, setText,
    startDateProp, endDateProp, setFilter, downloadDocsBulk, hiddenColumns = {}
}) {
    const defaultVersion = 'version-1'
    const versionMap = {
        "version-1": {
            initialSortState: { field: 'createdAt', sort: 'desc' }
        },
        "version-2": {
            initialSortState: { field: 'created_at', sort: 'desc' }
        },
        "version-3": {
            initialSortState: { field: 'alter_at', sort: 'desc' }
        },
        "version-4": {
            initialSortState: { field: "name", sort: "asc" }
        },
        "version-5": {
            initialSortState: { field: "productName", sort: "asc" }
        }
    }
    versionMap.undefined = {
        initialSortState: versionMap[defaultVersion]?.initialSortState
    };
    const initialSortState = versionMap?.[version]?.initialSortState
    const [loading, setLoading] = useState(false)
    const [rowsData, setRowsData] = useState([])
    const [columnsData, setColumnsData] = useState([])
    const [sort, setSort] = useState(initialSortState)
    const [searchFor, setSearchFor] = useState(searchData ? searchData : '');
    const [createdBy,setCreatedBy] = useState("");
    const [multiFilters, setMultiFilters] = useState(initialFilterState?.items || [{
        field: '', operator: 'contains', value: ''
    }]);
    const [columnVisibilityModelTable,setColumnVisibilityModel] = useState(columnVisibilityModel ||  {})
    const [limit, setLimit] = useState(10)
    // const [paginationData, setPaginationData] = useState(null)
    const [status, setStatus] = useState([])
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const auth = useSelector((state) => state?.auth);
    const selectedBox = useSelector((state) => state.selectedCheckBox);
    const pinTab = useSelector((state) => state?.pinTab)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isFirstRender = useRef(true); // Ref to track the first render
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [users,setUsers] = useState([]);   
    const [searchParams, setSearchParams] = useSearchParams();

    const [filtersPanel,setFiltersPanel] = useState(false);
    const [applyFilter,setApplyFilter] = useState(false);
    const [exportDocType,setExportDocType] = useState(null);

    useEffect(() => {
        setFilter?.(multiFilters);
    }, [multiFilters])

    const handleSearch = (event) => {
        setSearchFor(event?.target?.value);
        setText?.(event?.target?.value)
    }

    const debouncedSearchFor = useDebounce(searchFor, 550);

    const handleFilteredExport = (event) => {
        if(!delayedExport){
            setLoading(true);
        }
        event.preventDefault();
        let params = {
            sort,
            searchFor: searchFor ? searchFor?.trim() : searchData ? searchData : '',
            // filter: multiFilters?.[0]?.value ? multiFilters : undefined,
            status: status.join(',') || undefined,
            createdBy: createdBy ? createdBy : ''
        }
        if (searchKey) {
            params[searchKey] = searchFor ? searchFor?.trim() : searchData ? searchData : '';
        }

        if (filterName) {
            params[filterName] = multiFilters?.[0]?.value ? multiFilters : undefined;
        }

        if (date) {
            params['date'] = date;
        }

        let endDateData = endDate ? `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}` : new Date().toJSON().slice(0,10).replace(/-/g,'-');
        if (multiFilters?.some((item) => item.operator === 'between' && startDate && endDateData)) {
            let currentIndex = multiFilters.findIndex((item) => item.operator === 'between');
            multiFilters[currentIndex] = {
                ...multiFilters[currentIndex],
                startDate: `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`,
                endDate: endDateData,
                
            }
        }

        if (
            (
                multiFilters?.[0]?.field === 'createdAt' || multiFilters?.[0]?.field === 'created_at' ? 
                multiFilters?.[0]?.field :
                multiFilters?.[0]?.field && multiFilters?.[0]?.value
            ) || (multiFilters?.[0]?.value === 0 && multiFilters?.[0]?.field)
        ) {
            params.filter = multiFilters;
            if (filterName) {
                params[filterName] =  multiFilters;
            }
        }

        if (flagged) {
            params['flagged'] = flagged;
        }

        if (locationCode) {
            params['locationCode'] = locationCode;
        }

        if (startDateProp) {
            params.startDate = startDateProp
        }

        if (endDateProp) {
            params.endDate = endDateProp
        }
        
        axiosInstance
            .get(exportEndpoint, {
                params
            })
            .then((result) => {
                // const responseMessage = result?.data?.message;
                // const successMessage = responseMessage || "Exported Successfully!"
                if (result?.data?.signedUrlOfObject) {
                    downFn(result?.data?.signedUrlOfObject, exportFileName)
                } else {
                    const arrayOfRowObjects = result?.data?.data?.exportedData;
                    if (arrayOfRowObjects) {
                        downloadCSV(arrayOfRowObjects, exportFileName || `Export - ${new Date()}`);
                    }
                }
                // setSnackBar((prevSnackBar) => {
                //     return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                // });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
            setSnackBar((prevSnackBar) => {
                return {
                    ...prevSnackBar,
                    display: true,
                    message:
                        `${exportFileName} Report is being generated. Click here to check it's status.`,
                    type: "success",
                    redirectUrl: redirectUrlOnSnackBarClick,
                };
            });
    };

    const handleStatusFilter = (e, newValue) => {
        setStatus(newValue);
    }

    const buildParams = ({
        searchFor,
        searchKey,
        multiFilters,
        status,
        date,
        sort,
        limit,
        flagged,
        locationCode,
        startDateProp,
        endDateProp,
        createdBy,
        page,
    }) => {
        const params = {
            searchFor: searchFor?.trim(),
            status: status?.join(','),
            flagged,
            locationCode,
            startDate: startDateProp,
            endDate: endDateProp,
            createdBy,
            date,
            page,
            sort,
            limit
        };
    
        if (searchKey) params[searchKey] = searchFor?.trim();
    
        let endDateData = endDate ? `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}` : new Date().toJSON().slice(0,10).replace(/-/g,'-');
        if (multiFilters?.some((item) => item.operator === 'between' && startDate && endDateData)) {
            let currentIndex = multiFilters.findIndex((item) => item.operator === 'between');
            multiFilters[currentIndex] = {
                ...multiFilters[currentIndex],
                startDate: `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`,
                endDate: endDateData,
                
            }
        }
    
        if (
            (
                multiFilters?.[0]?.field === 'createdAt' || multiFilters?.[0]?.field === 'created_at' ? 
                multiFilters?.[0]?.field :
                multiFilters?.[0]?.field && multiFilters?.[0]?.value
            ) || (multiFilters?.[0]?.value === 0 && multiFilters?.[0]?.field)
        ) {
            params.filter = multiFilters;
            if (filterName) {
                params[filterName] =  multiFilters;
            }
        }

        if (flagged) {
            params['flagged'] = flagged;
        }

        if (locationCode) {
            params['locationCode'] = locationCode;
        }

        if (startDateProp) {
            params.startDate = startDateProp
        }

        if (endDateProp) {
            params.endDate = endDateProp
        }

        return params;
    };

    let abortController = new AbortController();

    const fetchData = async (page) => {
        setLoading(true);

        if (abortController) abortController.abort(); // Abort previous request
        abortController = new AbortController();

        const params = buildParams({
            searchFor,
            searchKey,
            multiFilters,
            status,
            date,
            sort,
            limit,
            page,
            flagged,
            locationCode,
            startDateProp,
            endDateProp,
            createdBy,
        });

        try {
            const response = await axiosInstance.get(endpoint, {
                params,
                signal: abortController.signal,
            });

            const data = response?.data?.data;
            isReactDispatch ? setData(data) : dispatch(setData(data));
        } catch (error) {
            if (error?.code !== "ERR_CANCELED") {
                console.error("Error in fetchData:", error);
                setSnackBar((prev) => ({
                    ...prev,
                    display: true,
                    message: error?.response?.data?.message || "Something went wrong",
                    type: "error",
                }));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadBulkDocs = (event) => {
        setLoading(true);
        event.preventDefault();
        const currentTimeStamp = () => moment(new Date()).format('YYYY-MM-DD_HH:MM:SSS');
        const newReportName = 'Order Pdf Documents';
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        let params = {
            uploadFileName : fileName,
            reportName : newReportName,
            docType: exportDocType,
            sort,
            searchFor: searchFor ? searchFor?.trim() : searchData ? searchData : '',
            filter: multiFilters?.length !== 0 ? multiFilters : undefined,
            status: status.join(',') || undefined,
            createdBy: createdBy ? createdBy : ''
        }
        if (searchKey) {
            params[searchKey] = searchFor ? searchFor?.trim() : searchData ? searchData : '';
        }

        if (filterName) {
            params[filterName] = multiFilters?.[0]?.value ? multiFilters : undefined;
        }

        params['selectedOrderName'] = rowSelectionModel?.length !== 10 ? rowSelectionModel : [];

        axiosInstance
            .get('/pdf/orders/docsList', { params })
            .then((response) => {
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: response?.data?.message, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
    }

    const handleSelectDocType = (value) => {
        setExportDocType(value)
    }

    const actionOnRowsSelected = async () => {
        setLoading(true)
        const payload = rowSelectionModel;
        await axiosInstance
            .post(`${rowSelectionEndpoint}`, {
                payload
            })
            .then((res) => {
                const successMessage = res?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                fetchData();
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const handleApplyFilter = () => {
        setApplyFilter(true);
        setFiltersPanel(false);
        fetchData();
    }

    const handleRemoveAllFilter = () => {
        multiFilters?.splice(0, multiFilters?.length);
        setMultiFilters([{ field: '', operator: 'contains', value: '' }])
        setApplyFilter(false);
        setFiltersPanel(false);
        fetchData();
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            console.log('first render');
            fetchData(1); // Fetch immediately on first render
            return;
        }

        const key = Object.keys(pinTab)?.find((key) => pinTab[key]?.name === tabName);

        if (type && key && pinTab[key]?.multiTabName !== type) {
            endpoint && fetchData(1);
        } else if (!key) {
            searchParams.set("page", 1);
            setSearchParams(searchParams);
            endpoint && fetchData(1);
        }
    }, [debouncedSearchFor, sort, reRender, limit, status, isMovementWithinVisible, createdBy, date, flagged, locationCode, startDateProp, endDateProp])


    useEffect(() => {
        if (data?.[dataPosition]?.length >= 0) {
            let finalColumns = columns?.length > 0
                ? columns
                : Object.keys(data[dataPosition]?.[0]).map((field) => {
                    return {
                        field: `${field}`,
                        headerName: `${field}`,
                        flex: 1,
                        headerClassName: 'super-app-theme--header',
                        headerAlign: 'center',
                        align: 'center',
                        editable: false,
                        sortable: false,
                        filterable: false,
                    }
                });

            if (!finalColumns[0]?.checkBoxColumn && checkBoxSelection) {

                finalColumns.unshift({
                    flex: 0.5,
                    ...GRID_CHECKBOX_SELECTION_COL_DEF,
                    headerClassName: 'super-app-theme--header',
                    checkBoxColumn: true,
                })
            }

            const formattedData = data?.[dataPosition]?.map((row) => {
                if (row?.created_at) {
                    return {
                        ...row,
                        created_at: moment(row?.created_at).format('MMM DD, YYYY h:mm:ss A'),
                        updated_at: moment(row?.updated_at).format('MMM DD, YYYY h:mm:ss A'),
                        cancelled_at: moment(row?.cancelled_at).format('MMM DD, YYYY h:mm:ss A'),
                        date_of_uploaded: moment(row?.date_of_uploaded).format('MMM DD, YYYY h:mm:ss A')
                    }
                    
                }
                else if(row?.alter_at){
                    return{
                        ...row,
                        alter_at : moment(row?.alter_at).format('MMM DD, YYYY h:mm:ss A'),                        
                    }
                } 
                else {
                    return {
                        ...row,
                        createdAt: moment(row?.createdAt).format('MMM DD, YYYY h:mm:ss A'),
                        updatedAt: moment(row?.updatedAt).format('MMM DD, YYYY h:mm:ss A'),
                        cancelledAt: moment(row?.cancelledAt).format('MMM DD, YYYY h:mm:ss A'),
                    }
                }
            })

            setRowsData(formattedData)
            setColumnsData(finalColumns)
        }

    }, [data])

    useEffect(() => {
        dispatch(setSelectedCheckBox({ ...selectedBox, ids: rowSelectionModel, function: actionOnRowsSelected }));
    }, [rowSelectionModel])

    const handleRowSelection = (arrayOfRowsSelectedIds) => {
        setRowSelectionModel(arrayOfRowsSelectedIds);
        if (setSelectedId) {
            setSelectedId(arrayOfRowsSelectedIds)
        }
    };

    const timestampOnlyOperators = [
        {
            label: 'Between',
            value: 'between',
            InputComponent: TimeStampInputValue,
            InputComponentProps: { startDate, endDate, setStartDate, setEndDate },
            getValueAsString: (startDate) => `${startDate} - ${endDate}`,
        },
    ];

    const enumOnlyOperators = (enumValues, tableName, columnName) => [
        {
            label: 'Equals',
            value: 'equal',
            InputComponent: (props) => EnumInputValue({ enumValues, ...props }),
            InputComponentProps: { tableName, columnName },
            getValueAsString: (value) => `${value}`,
        },
        {
            label: 'Not Equals',
            value: 'notEqual',
            InputComponent: (props) => EnumInputValue({ enumValues, ...props }),
            InputComponentProps: { tableName, columnName },
            getValueAsString: (value) => `${value}`,
        },
    ];

    columns = React.useMemo(
        () =>
            columns.map((col) => {
                if (col?.enumValues || col?.type === 'enum') {
                    return {
                        ...col,
                        filterOperators: enumOnlyOperators(col?.enumValues, col?.tableName, col?.columnName),
                    }
                } else if (
                    col.field === 'createdAt'
                    || col.field === 'created_at'
                    || col.field === 'updatedAt'
                    || col.field === 'alter_at'
                    || col.type === 'timestamp'
                ) {
                    return {
                        ...col,
                        filterOperators: timestampOnlyOperators,
                    }
                }else if(col?.type === 'number'){
                    return {
                        ...col,
                        filterOperators: getGridNumericOperators().filter(
                        (operator) => operator.value === '=',
                      ),
                    }
                }
                return col;
            }),
        [columns],
    );

    const handleUserSelect = async (selectedUsers) => {
        setCreatedBy(selectedUsers?.name);
    };

    return (
        <Fragment>
            <SnackBar snackBar={snackBar}  setSnackBar={setSnackBar} />
            <div className={Classes.Container} style={{ width: '100%' }} >

                <div style={{ width: '100%' }}>
                    {
                        rowsData &&
                        <Box className="shadow"
                            component={Paper}
                            sx={{
                                '& .super-app-theme--header': {
                                    backgroundColor: '#243750',
                                    color: '#ffffff',
                                    cursor: "default"
                                },
                            }}>
                            <DataGrid
                                rows={rowsData}
                                getRowId={(row) => row[rowId]}
                                columns={columnsData}
                                columnVisibilityModel={columnVisibilityModelTable}
                                onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
                                slots={{ toolbar: CustomToolbar }}
                                slotProps={{
                                    toolbar: {
                                        searchFor,
                                        handleSearch,
                                        handleStatusFilter,
                                        dataPosition,
                                        extraToolBarButtons,
                                        service,
                                        handleFilteredExport,
                                        filteredExport: exportEndpoint ? true : false,
                                        createdByFilter,
                                        handleUserSelect, 
                                        users, 
                                        setUsers,
                                        filtersPanel,
                                        setFiltersPanel,
                                        multiFilters, 
                                        setMultiFilters,
                                        handleApplyFilter,
                                        applyFilter,
                                        setApplyFilter,
                                        handleRemoveAllFilter,
                                        downloadDocsBulk,
                                        rowSelectionModel,
                                        handleDownloadBulkDocs,
                                        handleSelectDocType,
                                        exportDocType
                                    }
                                }}
                                filterMode="server"
                                sortingMode="server"
                                paginationMode="server"
                                pageSizeOptions={[10, 25, 35, 50, 100]}
                                disableRowSelectionOnClick
                                checkboxSelection={checkBoxSelection}
                                autoHeight
                                // onFilterModelChange={(filter) => (setFilter(filter.items[0]))}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    handleRowSelection(newRowSelectionModel)
                                }}
                                rowSelectionModel={rowSelectionModel}
                                getRowHeight={() => "auto"}
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important",
                                    },
                                    // ".css-1t5wrdm-MuiDataGrid-filterForm": {
                                    //     border: "2px solid red !important",
                                    // },
                                    "& .MuiPopper-root-MuiDataGrid-panel": {
                                        border: "2px solid green !important",
                                    },
                                    "& .MuiTablePagination-displayedRows": { display: 'none' },
                                    "& .MuiTablePagination-actions": { display: 'none' },
                                    ".PrivateSwitchBase-input": {
                                        height: "23px",
                                        margin: "10px 13px",
                                        // width: "20px"
                                    },
                                    cursor: "pointer",
                                    '& .MuiDataGrid-cell': {
                                        py: '10px',
                                    },
                                    '& .MuiDataGrid-sortIcon': {
                                        opacity: 1,
                                        color: "#fff",
                                    },
                                }}
                                onRowClick={(params) => {
                                    const permissible = CheckRoleBasedPermission(auth?.user, services?.[service], permission?.read);
                                    if (permissible && navigateOnRowClickEndpoint) {
                                        const url = `${navigateOnRowClickEndpoint}/${params?.id || params?.[rowId]}`;
                                        window.open(url, '_blank');
                                    }
                                }}
                                initialState={{
                                    columns: { columnVisibilityModel: columnVisibilityModelTable },
                                    pagination: {
                                        paginationModel: { pageSize: 10, page: 0 },
                                    },
                                }}
                                onSortModelChange={(sort) => setSort(sort[0])}
                                onPaginationModelChange={({ pageSize }) => {
                                    if (pageSize >= 10) {
                                        setLimit(pageSize)
                                    } else {
                                        setLimit(10)
                                    }
                                }
                                }
                            />
                        </Box>
                    }
                </div>

                {loading ? <Spinner /> : null}

                {data?.[dataPosition]?.length > 0 && (
                    <div style={{ margin: '20px auto 0px', paddingBottom: 20 }}>
                        <NewPagination totalPage={data} fetchData={fetchData} />
                    </div>
                )}
            </div>
        </Fragment >
    );
}