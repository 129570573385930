import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SnackBar from '../../../components/SnackBar';
import { TextField, Autocomplete, FormControlLabel, Switch } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';
import Classes from "../customers/css/Modal.module.css";
import Spinner from '../../../components/spinner';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateFranchise = (props) => {
    const { open, closeDialog, setReRender, reRender } = props;

    const [loading, setLoading] = useState(false);
    const [pincode, setPincode] = useState('');
    const [num, setNum] = useState('');
    const [gstNO, setGstNO] = useState('');
    const [locationCode, setLocationCode] = useState([]);
    const [states, setStates] = useState([]);
    const [locations, setLocations] = useState([]);
    const [cities,setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [skipQcStep, setSkipQcStep] = useState(false);

    const fetchLocation = () => {
        axiosInstance.get('/location/locations?type=WH&text=')
            .then(res => {
                const locations = [];
                if (res.data.data.length !== 0) {
                    for (let location of res?.data?.data) {
                        locations.push(location?.locationCode)
                    }
                }
                setLocations(locations)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        fetchLocation()
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            name: event.target.name.value,
            gst: event.target.gst.value,
            address: event.target.address.value,
            pincode: event.target.pincode.value,
            phone: event.target.phone.value,
            state: event.target.state.value,
            city: event.target.city.value,
            pan: event.target.pan.value,
            email: event.target.email.value,
            bank_account_name: event.target.bank_account_name.value,
            bank_account_number: event.target.bank_account_number.value,
            bank_account_ifsc: event.target.bank_account_ifsc.value,
            bank: event.target.bank.value,
            invoice_prefix: event.target.invoice_prefix.value,
            invoice_infix: event.target.invoice_infix.value,
            cin: event?.target?.cin?.value ? event?.target?.cin?.value : undefined,
            district: event.target.district.value,
            locations: locationCode,
            company_name: event?.target?.company_name?.value,
            skip_qc_step: JSON.parse(skipQcStep),
            customInvoiceSeq: event?.target?.customInvoiceSeq?.value ? event?.target?.customInvoiceSeq?.value : undefined,
            draft_order_prefix: event?.target?.draft_order_prefix?.value,
            draft_order_suffix: event?.target?.draft_order_suffix?.value,
            order_prefix: event?.target?.order_prefix?.value,
            order_suffix: event?.target?.order_suffix?.value,
            note_optional: event?.target?.note_optional?.checked,
            refrence_number_optional: event?.target?.refrence_number_optional?.checked,
            parchi_made_by_optional: event?.target?.parchi_made_by_optional?.checked
        }

        axiosInstance
            .post(`/pos/createFranchise`, { ...data })
            .then((res) => {
                const form = document.getElementById('form');
                form.reset();
                handleClose();
                setReRender(!reRender);
                setLoading(false);
                setSnackBar({ display: true, type: "success", message: res?.data?.message })
            }).catch((err) => {
                setLoading(false);
                setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
            })
    }

    const handleClose = () => {
        const form = document.getElementById('form');
        form.reset();
        setNum('');
        setPincode('');
        setGstNO('');
        locationCode.splice(0, locationCode.length);
        document.getElementById('isValidGst').style.display = 'none';
        document.getElementById('pinErrMsg').style.display = 'none';
        document.getElementById('phoneErrMsg').style.display = 'none';
        document.getElementById('isValidPAN').style.display = 'none';
        document.getElementById('isValidCIN').style.display = 'none';
        closeDialog();
    }


    const handleValidGst = (event) => {
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidGst').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidGst').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidGst').style.display = 'block';
            }
        }
    }

    const handleValidPAN = (event) => {
        let regex = new RegExp(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidPAN').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidPAN').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidPAN').style.display = 'block';
            }
        }
    }

    const isValidCINNumber = (event) => {
        let regex = new RegExp(/^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/);

        if (event.target.value == null) {
            return "false";
        }
     
        if (regex.test(event.target.value) === true) {
            document.getElementById('btn').disabled = false;
            document.getElementById('isValidCIN').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('btn').disabled = false;
                document.getElementById('isValidCIN').style.display = 'none';
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('isValidCIN').style.display = 'block';
            }
        }
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleNumChange = event => {
        const limit = 10;
        setNum(event.target.value.slice(0, limit));
    };

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if (event.target.value.match(phoneno)) {
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }

    const handleMinLengthPinCode = (event) => {
        if (event.target.value) {
            const pincode = /^\d{6}$/;
            if (event.target.value.match(pincode)) {
                document.getElementById('pinErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('pinErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('pinErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }


    async function handlePincodeChange(event) {
        const limit = 6;
        const enteredPincode = event.target.value.slice(0, limit)
        setPincode(enteredPincode);
    }

    useEffect(() => {
        axiosInstance
            .get('/others/AllStates')
            .then((response) => {
                const states = [];
                for (let stateName of response.data.data) {
                    states.push({ id: stateName?._id, label: stateName?.state })
                }
                setStates(states)
            })
    }, [])

    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllCities')
            .then((response) => {
                setCities(response.data.data)
            })
    }, [])

    useEffect(() => {
        axiosInstance
            .get(`/pincode/getAllDistricts`)
            .then((res) => {
                const districts = res?.data?.data;
                setDistricts(districts);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Unable to fetch districts'
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        
    }, []);

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                sx={{'& .MuiDialog-paper': {maxWidth:'775px'}}}
            >
                <DialogTitle>Create Franchise</DialogTitle>
                <form method="post" onSubmit={handleSubmit} id='form' >
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{width:'32%'}}>
                                <TextField
                                    label={'Name'}
                                    placeholder={'Enter franchise name'}
                                    required={true}
                                    id='name'
                                    name={'name'}
                                    autoComplete='off'
                                    size="small"
                                    sx={{ width: '100%' }}
                                />
                            </div>

                            <div style={{ width: '32%' }}>
                                <TextField
                                    label={'GST'}
                                    placeholder={'Enter franchise GST'}
                                    required={true}
                                    id='gst'
                                    name={'gst'}
                                    minLength={15}
                                    maxLength={15}
                                    inputProps={{ maxLength: 15 }}
                                    onBlur={handleValidGst}
                                    onChange={(event) => setGstNO(event.target.value)}
                                    value={gstNO}
                                    autoComplete='off'
                                    autoFocus='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                />

                                <div id='isValidGst' className={Classes.IsValidGst}>
                                    <p>Please fill valid Gst Number</p>
                                </div>
                            </div>

                            <div style={{ width: '32%' }}>
                                <TextField
                                    type="number"
                                    label={'Phone'}
                                    placeholder={'Enter franchise phone'}
                                    required={true}
                                    id='phone'
                                    name={'phone'}
                                    maxLength={10}
                                    onChange={handleNumChange}
                                    onBlur={(e) => handleMinLength(e)}
                                    onKeyDown={preventNegativeValues}
                                    value={num ? num : ''}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                />

                                <div className={Classes.PhoneErrorMsg} id='phoneErrMsg' >
                                    <p>Please enter valid 10 digit number</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
                            <TextField
                                label={'Email'}
                                type='email'
                                placeholder={'Enter franchise email'}
                                required={true}
                                id='email'
                                name={'email'}
                                autoComplete='off'
                                sx={{ width: '32%' }}
                                size="small"
                            />

                            <div style={{width: '32%'}}>
                                <TextField
                                    label={'PAN No.'}
                                    placeholder={'Enter franchise PAN No.'}
                                    required={true}
                                    id='pan'
                                    name={'pan'}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    onBlur={(e) => handleValidPAN(e)}
                                    minLength={10}
                                    maxLength={10}
                                    inputProps={{ maxLength: 10 }}
                                    size="small"
                                />

                                <div id='isValidPAN' className={Classes.IsValidGst}>
                                    <p>Please fill valid PAN Number</p>
                                </div>
                            </div>

                            <div style={{width: '32%'}}>
                                <TextField
                                    label={'CIN No.'}
                                    placeholder={'Enter franchise CIN No.'}
                                    id='cin'
                                    name={'cin'}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                    minLength={21}
                                    maxLength={21}
                                    inputProps={{ maxLength: 21 }}
                                    onBlur={(e) => isValidCINNumber(e)}
                                />

                                <div id='isValidCIN' className={Classes.IsValidGst}>
                                    <p>Please fill valid CIN Number</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{width:'32%'}}>
                                <TextField
                                    label={'Address'}
                                    placeholder={'Enter franchise address'}
                                    required={true}
                                    id='address'
                                    name={'address'}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                />
                            </div>
                            
                            <div style={{width:'32%'}}>
                                <Autocomplete
                                    disablePortal
                                    id={'city'}
                                    name={'city'}
                                    required={true}
                                    options={cities}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) =>
                                        <TextField {...params} label='City' required={true} />
                                    }
                                    size="small"
                                />
                            </div>

                            <div style={{width:'32%'}}>
                                <Autocomplete
                                    disablePortal
                                    id={'district'}
                                    name={'district'}
                                    required={true}
                                    options={districts}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) =>
                                        <TextField {...params} label={'District'} required={true} />
                                    }
                                    size="small"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width:'32%' }}>
                                <TextField
                                    type="number"
                                    label={'Pincode'}
                                    placeholder={'Enter pincode'}
                                    required={true}
                                    id='pincode'
                                    name={'pincode'}
                                    value={pincode}
                                    onBlur={(e) => handleMinLengthPinCode(e)}
                                    onChange={handlePincodeChange}
                                    onKeyDown={preventNegativeValues}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                />

                                <div className={Classes.PhoneErrorMsg} id='pinErrMsg' style={{ fontSize: 14 }}>
                                    <p>Please enter a valid 6 digit pincode</p>
                                </div>
                            </div>

                            <div style={{ width:'32%' }}>
                                <Autocomplete
                                    required={true}
                                    disablePortal
                                    id="state"
                                    name='state'
                                    options={states}
                                    ListboxProps={{ sx: { fontSize: 14 } }}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} label="States" required />}
                                    size="small"
                                />
                            </div>
                            <div style={{width:'32%'}}>
                                <TextField
                                    label={'Company Name'}
                                    placeholder={'Enter franchise company name'}
                                    id='company_name'
                                    name={'company_name'}
                                    autoComplete='off'
                                    size="small"
                                    sx={{ width: '100%' }}
                                />
                            </div>
                            
                        </div>

                        <div style={{marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width:'66%' }}>
                                <Autocomplete
                                    freeSolo
                                    disablePortal
                                    id="locations"
                                    name="locations"
                                    options={locations}
                                    sx={{ width: '100%' }}
                                    required
                                    multiple
                                    onChange={(event, newValue) => setLocationCode(newValue)}
                                    renderInput={(params) =>
                                        <TextField {...params} label={"Locations"} />
                                    }
                                    size="small"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: 15 }}>
                            <p>Bank Details</p>
                            <div style={{ marginTop: 5, display: 'flex', justifyContent: 'space-between' }}>
                                <TextField
                                    label={'A/C Holder Name'}
                                    placeholder={'Enter A/C Holder Name'}
                                    required={true}
                                    id='bank_account_name'
                                    name={'bank_account_name'}
                                    autoComplete='off'
                                    sx={{ width: '32%' }}
                                    size="small"

                                />
                                <TextField
                                    label={'A/C Number'}
                                    placeholder={'Enter A/C Number'}
                                    required={true}
                                    type='number'
                                    id='bank_account_number'
                                    name={'bank_account_number'}
                                    autoComplete='off'
                                    sx={{ width: '32%' }}
                                    onKeyDown={preventNegativeValues}
                                    size="small"
                                />

                                <TextField
                                    label={'IFSC Code'}
                                    placeholder={'Enter IFSC Code'}
                                    required={true}
                                    id='bank_account_ifsc'
                                    name={'bank_account_ifsc'}
                                    autoComplete='off'
                                    sx={{ width: '32%' }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '32%' }}>
                                <TextField
                                    label={'Bank Name'}
                                    placeholder={'Enter Bank Name'}
                                    required={true}
                                    id='bank'
                                    name={'bank'}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <p>
                                Invoice Details
                            </p>
                            <span style={{fontSize: '12px', fontWeight: 500}}>Example : <i>HM-STO</i> (prefix) / <i>24-25</i> (infix) / XXXX</span>
                            <div style={{ marginTop: 5, display: 'flex', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        width: '35%',
                                    }}
                                >
                                    <TextField
                                        label={'Invoice Prefix'}
                                        placeholder={'Set Invoice Prefix'}
                                        required={true}
                                        id='invoice_prefix'
                                        name={'invoice_prefix'}
                                        autoComplete='off'
                                        sx={{ width: '100%' }}
                                        size="small"
                                    />
                                    <div style={{
                                        "fontSize": "small",
                                        "color": "gray",
                                        "textAlign": "center",
                                    }}>
                                        <span><b>STORE-PREFIX</b></span>
                                        <span>/</span>
                                        <span>STORE-INFIX</span>
                                        <span>/</span>
                                        <span>12</span>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '35%',
                                    }}
                                >
                                    <TextField
                                        label={'Invoice Infix'}
                                        placeholder={'Set Invoice Infix'}
                                        id='invoice_infix'
                                        name={'invoice_infix'}
                                        autoComplete='off'
                                        sx={{ width: '100%' }}
                                        size="small"
                                    />
                                    <div style={{
                                        "fontSize": "small",
                                        "color": "gray",
                                        "textAlign": "center",
                                    }}>
                                        <span>STORE-PREFIX</span>
                                        <span>/</span>
                                        <span><b>STORE-INFIX</b></span>
                                        <span>/</span>
                                        <span>12</span>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '30%',
                                    }}
                                >
                                    <TextField
                                        label={'Starting Seq.'}
                                        placeholder={'Set Starting Seq. (optional)'}
                                        id='customInvoiceSeq'
                                        name={'customInvoiceSeq'}
                                        autoComplete='off'
                                        sx={{ width: '100%' }}
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <p>Franchise Settings</p>
                            <div 
                                style={{
                                    marginTop: 15,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "1rem",
                                    flexWrap: "wrap",
                                }}
                            >
                                <div style={{ flex: "1 1 66%" }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="skip-qc-step"
                                        name="skip_qc_step"
                                        options={['true', 'false']}
                                        sx={{ width: '100%' }}
                                        required
                                        onChange={(event, newValue) => setSkipQcStep(newValue)}
                                        renderInput={(params) =>
                                            <TextField {...params} label={"Skip Qc Step"} />
                                        }
                                        size="small"
                                    />
                                </div>

                                <FormControlLabel
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize:'12px' }}}
                                    control={ <Switch name="note_optional" /> }
                                    label="Note Optional"
                                />
                            </div>

                            <div
                                style={{
                                    marginTop: 10,
                                    display: "flex",
                                    gap: "1rem",
                                    flexWrap: "wrap",
                                }}
                            >
                                <TextField
                                    label={'Draft Order Prefix'}
                                    type='text'
                                    placeholder={'Enter Draft order prefix'}
                                    key={'draft_order_prefix'}
                                    id='draft_order_prefix'
                                    name={'draft_order_prefix'}
                                    autoComplete='off'
                                    sx={{ flex: "1 1 32.5%" }}
                                    size="small"
                                />

                                <TextField
                                    label={'Draft Order Suffix'}
                                    type='text'
                                    placeholder={'Enter Draft order suffix'}
                                    key={'draft_order_suffix'}
                                    id='draft_order_suffix'
                                    name={'draft_order_suffix'}
                                    autoComplete='off'
                                    sx={{ flex: "1 1 32.5%" }}
                                    size="small"
                                />

                                <FormControlLabel
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize:'12px' }}}
                                    control={ <Switch name="refrence_number_optional" /> }
                                    label="Refrence Num.. Optional"
                                />
                            </div>

                            <div
                                style={{
                                    marginTop: 10,
                                    display: "flex",
                                    gap: "1rem",
                                    flexWrap: "wrap",
                                }}
                            >
                                <TextField
                                    label={'Order Prefix'}
                                    type='text'
                                    placeholder={'Enter order prefix'}
                                    key={'order_prefix'}
                                    id='order_prefix'
                                    name={'order_prefix'}
                                    autoComplete='off'
                                    sx={{ flex: "1 1 32.5%" }}
                                    size="small"
                                />

                                <TextField
                                    label={'Order Suffix'}
                                    type='text'
                                    placeholder={'Enter order suffix'}
                                    key={'order_suffix'}
                                    id='order_suffix'
                                    name={'order_suffix'}
                                    autoComplete='off'
                                    sx={{ flex: "1 1 32.5%" }}
                                    size="small"
                                />

                                <FormControlLabel
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize:'12px' }}}
                                    control={<Switch name="parchi_made_by_optional" /> }
                                    label="Parchi Made By Optional"
                                />
                            </div>
                        </div>
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose}>CLOSE</Button>
                        <Button type='submit' id='btn'>CREATE</Button>
                    </DialogActions>
                </form>

                {loading ? <Spinner /> : null}
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}

export default CreateFranchise;