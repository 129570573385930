import { Fragment, useState, forwardRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, MenuItem } from '@mui/material';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogContentText } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SnackBar from '../../../components/SnackBar';
import axiosInstance from '../../../utility/axios-instance';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const PutAwayPopup = (props) => {
    const { title, open, handleClose, locationCode, returnId } = props;
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [formData, setFormData] = useState([]);
    const [inbounds, setInbounds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [bins, setBins] = useState([]);
    const [rejectedBins, setRejectedBins] = useState([]);

    const closePopup = () => {
        setFormData([
            ...inbounds?.map((inbound) => {
                return {
                    id: inbound?.id,
                    saleableBinCode: inbound?.qcPass > 0 ? bins?.find((bin) => { return bin?.default === true })?.code || null : null,
                    rejectedBinCode: inbound?.qcFail > 0 ? rejectedBins?.find((bin) => { return bin?.default === true })?.code || null : null,
                };
            }),
        ]);
        handleClose();
    };
    useEffect(() => {
        const filter = JSON.stringify({
            "field": "orderName",
            "value": returnId?.orderName,
        })
        axiosInstance
            .get(`/pgOrder/admin/returnsQcMarked`, {
                params: {
                    "filter": filter,
                    type: "purchaseOrder",
                    limit: returnId?.totalInboundLineItems
                }
            })
            .then((res) => {
                const responseData = res?.data?.data
                setInbounds(responseData?.fetchedReturns)
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false)
            });
    }, [returnId]);

    useEffect(() => {
        if (locationCode) {
            axiosInstance.get(`/inventory/bins?locationCode=${locationCode}&type=Saleable`)
                .then(res => {
                    let bins = res?.data?.data?.bins;
                    setBins(bins)
                })
                .catch(error => {
                    console.log(error);
                })

            axiosInstance.get(`/inventory/bins?locationCode=${locationCode}&type=Rejected`)
                .then(res => {
                    let bins = res?.data?.data?.bins;
                    setRejectedBins(bins)
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, [locationCode])

    useEffect(() => {
        setFormData([
            ...inbounds?.map((inbound) => {
                return {
                    id: inbound?.id,
                    saleableBinCode: inbound?.qcPass > 0 ? bins?.find((bin) => { return bin?.default === true })?.code || null : null,
                    rejectedBinCode: inbound?.qcFail > 0 ? rejectedBins?.find((bin) => { return bin?.default === true })?.code || null : null,
                };
            }),
        ]);
    }, [bins, rejectedBins, inbounds]);

    const handlePutawaySubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const payloads = formData.map((row) => {
            return {
                id: row?.id,
                ...(row?.saleableBinCode && { saleableBinCode: row?.saleableBinCode }),
                ...(row?.rejectedBinCode && { rejectedBinCode: row?.rejectedBinCode }),
            };
        });
        const putAwayPo = async (payload, inboundId) => {
            delete payload?.id;
            const data = {
                ...payload
            }
            await axiosInstance.post(`purchaseOrders/putAwayInboundPo/${inboundId}`, data)
                .then(res => {
                    const successMessage = 'Order PutAway Successfully'
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                    handleClose();
                })
                .catch(error => {
                    console.log(error);
                    const errorMessage = error?.response?.data?.message
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                    });
                })
        }
        for (let payload of payloads) {
            if (payload?.saleableBinCode === null && payload?.rejectedBinCode === null) {
                alert('Atleast provide one bin code!');
                return
            }
            await putAwayPo(payload, payload?.id);
        }
        setLoading(false);
    }
    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>{`${title} Purchaser Order`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Purchase Order Name: {returnId?.orderName}
                    </DialogContentText>
                    <DialogContentText>
                        Location Code: {returnId?.locationCode}
                    </DialogContentText>
                    <DialogContentText>
                        Bin Selected Items: {formData?.filter((row) => row?.saleableBinCode || row?.rejectedBinCode).length} / {formData?.length}
                    </DialogContentText>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px', marginTop: '10px' }}>
                        <div style={{ flex: '0.6', textAlign: 'center' }}>SKU</div>
                        <div style={{ flex: '0.6', textAlign: 'center' }}>Inbound No.</div>
                        <div style={{ flex: '0.5', textAlign: 'center' }}>Total Qty.</div>
                        <div style={{ flex: '0.5', textAlign: 'center' }}>Pass Qty.</div>
                        <div style={{ flex: '0.5', textAlign: 'center' }}>Fail Qty.</div>
                        <div style={{ flex: '0.6', textAlign: 'center' }}>Staging Bin</div>
                        <div style={{ flex: '0.8', textAlign: 'center' }}>Saleable Bin</div>
                        <div style={{ flex: '0.8', textAlign: 'center' }}>Rejected Bin</div>
                    </div>
                    <div style={{ margin: '8px 0px' }} >
                        <form onSubmit={handlePutawaySubmit} >
                            {inbounds?.length > 0 && formData?.length && inbounds?.map((inbound, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ flex: '0.9', textAlign: 'center' }}>{inbound?.sku}</div>
                                            <div style={{ flex: '0.8', textAlign: 'center' }}>{inbound?.inboundNumber}</div>
                                            <div style={{ flex: '0.8', textAlign: 'center' }}>{inbound?.quantity}</div>
                                            <div style={{ flex: '0.8', textAlign: 'center' }}>{inbound?.qcPass > 0 ? inbound?.qcPass : 0}</div>
                                            <div style={{ flex: '0.8', textAlign: 'center' }}>{inbound?.qcFail > 0 ? inbound?.qcFail : 0}</div>
                                            <div style={{ flex: '1', textAlign: 'center' }}>{inbound?.stagingBinCode}</div>
                                            <div style={{ position: "relative", flex: '1.2' }}>
                                                <Autocomplete
                                                    size='small'
                                                    disabled={((inbound?.qcPass > 0) && locationCode !== null) ? false : true}
                                                    id="binId"
                                                    required
                                                    options={bins.map((bin) => bin.code)}
                                                    value={formData?.find((row) => row?.id === inbound?.id)?.saleableBinCode || null}
                                                    onChange={(event, newValue) => {
                                                        setFormData((prevFormData) => {
                                                            const newFormData = [...prevFormData];
                                                            const index = newFormData.findIndex((row) => row?.id === inbound?.id);
                                                            newFormData[index].saleableBinCode = newValue || null;
                                                            return newFormData;
                                                        });
                                                    }}
                                                    renderInput={(params) => {
                                                        return (
                                                            <TextField
                                                                required={inbound?.qcPass > 0 ? true : false}
                                                                {...params}
                                                                label="Pass Bin"
                                                            />
                                                        );
                                                    }}
                                                />
                                                <Tooltip title="Auto-fill" sx={{ position: 'absolute', right: '-5px', bottom: '-10px' }} >
                                                    <IconButton
                                                        size='small'
                                                        disabled={((inbound?.qcPass > 0) && locationCode !== null) ? false : true}
                                                        onClick={() => {
                                                            const saleableBinCode = formData?.find((row) => row?.id === inbound?.id)?.saleableBinCode || null;
                                                            setFormData((prevFormData) => {
                                                                return prevFormData.map((row) => ({
                                                                    ...row,
                                                                    saleableBinCode: saleableBinCode,
                                                                }));
                                                            });
                                                        }}
                                                    >
                                                        <ContentCopyIcon
                                                            fontSize='small'
                                                            sx={{ 
                                                                cursor: 'pointer', 
                                                                width: '10px', 
                                                                height: '10px',
                                                                '&:hover': {
                                                                    color: "rgb(255, 98, 0)"
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div style={{ position: "relative", flex: '1.2' }}>
                                                <Autocomplete
                                                    size='small'
                                                    disabled={((inbound?.qcFail > 0) && locationCode !== null) ? false : true}
                                                    id="rejectBinId"
                                                    required
                                                    options={rejectedBins.map((bin) => { return bin?.code })}
                                                    value={formData?.find((row) => row?.id === inbound?.id)?.rejectedBinCode || null}
                                                    onChange={(event, newValue) => {
                                                        setFormData((prevFormData) => {
                                                            const newFormData = [...prevFormData];
                                                            const index = newFormData.findIndex((row) => row?.id === inbound?.id);
                                                            newFormData[index].rejectedBinCode = newValue || null;
                                                            return newFormData;
                                                        });
                                                    }}
                                                    renderInput={(params) => {
                                                        return (
                                                            <TextField
                                                                required={inbound?.qcFail > 0 ? true : false}
                                                                {...params}
                                                                label="Reject Bin"
                                                            />
                                                        )
                                                    }}
                                                />
                                                <Tooltip title="Auto-fill" sx={{ position: 'absolute', right: '-5px', bottom: '-10px' }} >
                                                    <IconButton
                                                        size='small'
                                                        disabled={((inbound?.qcFail > 0) && locationCode !== null) ? false : true}
                                                        onClick={() => {
                                                            const rejectedBinCode = formData?.find((row) => row?.id === inbound?.id)?.rejectedBinCode || null;
                                                            setFormData((prevFormData) => {
                                                                return prevFormData.map((row) => ({
                                                                    ...row,
                                                                    rejectedBinCode: rejectedBinCode,
                                                                }));
                                                            });
                                                        }}
                                                    >
                                                        <ContentCopyIcon
                                                            fontSize='small'
                                                            sx={{ 
                                                                cursor: 'pointer', 
                                                                width: '10px', 
                                                                height: '10px',
                                                                '&:hover': {
                                                                    color: "rgb(255, 98, 0)"
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            })}
                            <DialogActions>
                                <Button type="submit" id='move' variant="contained" color="primary" >Putaway</Button>
                                <Button onClick={closePopup}>Close</Button>
                            </DialogActions>
                        </form>
                    </div>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
}

export default PutAwayPopup;