import React, { Fragment, useState } from 'react';
import Table from '../../../components/table/table';
import { Flag } from '@mui/icons-material';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import { Button } from '@mui/material';
import UpdateLowerAndUpperLimit from './updateLowerAndUpperLimit';
import { ImportFileComponent } from './../../../components/importFileComponent';
import {Switch, FormControlLabel } from '@mui/material';
import moment from 'moment';


const LowInventoryTracker = () => {
    const currentTimeStamp = () => moment(new Date()).format('YYYY-MM-DD_HH:MM:SSS');
    const [data, setData] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [update, setUpdate] = useState(null);
    const [flagged, setFlagged] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [text, setText] = useState('');

    const flagInventory = async (id, data) => {
        axiosInstance.put(`/inventory/flagInventory/${id}`, data).then(res => {
            setReRender(prev => !prev);
        }).catch(err => {
            setSnackBar(prev => ({
                ...prev,
                display: true,
                type: 'error',
                message: err?.response?.data?.message ?? "Something went wrong"
            }));
        })
    };

    const AvailableQty = (row) => {
        return (
            <p style={{ color: row.flagged ? 'red' : 'inherit' }}>{row.available_qty}</p>
        )
    };

    const RefillQty = (row) => {
        return (
            <p style={{ color: row.flagged ? 'red' : 'inherit' }}>{row.refill_qty}</p>
        )
    };

    const FinalRefillQty = (row) => {
        return (
            <p style={{ color: row.flagged ? 'red' : 'inherit' }}>{row.final_refill_qty}</p>
        )
    };

    const TotalPoQty = (row) => {
        return row.totalPoQuantity ?? '--'
    };

    const FlaggedRenderCell = (row) => {
        return (
            <Flag 
                style={{ color: row.flagged ? 'red' : 'gray' }} 
                onClick={() => { flagInventory(row.id, {flagged: !row.flagged})  }} 
            />
        )  
    };

    const UpdateButtonRenderCell = (row) => {
        return (
            <Button variant='contained' size='small' onClick={() => { setUpdate(row)}}>
                Update
            </Button>
        )
    };

    const PortfolioToggleRenderCell = (row) => {
        return (
            <Switch 
                size='small' 
                checked={row?.portfolio_toggle} 
                onClick={() => { flagInventory(row.id, {portfolio_toggle: !row?.portfolio_toggle}) }} 
            />
        )
    };

    const StockStrategyRenderCell = (row) => {
        return (
            <Switch 
                size='small' 
                checked={row?.stock_strategy} 
                onClick={() => { flagInventory(row.id, {stock_strategy: !row?.stock_strategy}) }} 
            />
        )
    };

    const ShowRoomDisplayRenderCell = (row) => {
        return (
            <Switch 
                size='small' 
                checked={row?.showroom_display} 
                onClick={() => { flagInventory(row.id, {showroom_display: !row?.showroom_display}) }} 
            />
        )
    };

    const LastPoDateRenderCell = (row) => {
        return (
            <p>{row.lastPoDate ? moment(row?.lastPoDate).format('MMM DD, YYYY h:mm:ss A') : '--'}</p>
        )
    };

    const LastPoNumberRenderCell = (row) => {
        return (
            <p>{row.lastPoDate ? row.lastPoNumber : '--'}</p>
        )
    };

    const columns = [
        {
            field: 'sku_code',
            headerName: 'SKU Code',
            headerClassName: 'super-app-theme--header',
            filterable : true,
            width: 150
        },
        {
            field: 'product_name',
            headerName: 'Product Name',
            headerClassName: 'super-app-theme--header',
            filterable : true,
            width: 300
        },
        { 
            field: 'available_qty', 
            headerName: 'Available Qty', 
            headerClassName: 'super-app-theme--header',
            filterable : true,
            renderCell: (params) => ( <AvailableQty {...params?.row} />),
            customRenderCell: AvailableQty
        },
        { 
            field: 'refill_qty', 
            headerName: 'Refill Qty', 
            headerClassName: 'super-app-theme--header',
            filterable : false,
            renderCell: (params) => ( <RefillQty {...params?.row} />),
            customRenderCell: RefillQty
        },
        { 
            field: 'final_refill_qty', 
            headerName: 'Final Refill Qty', 
            headerClassName: 'super-app-theme--header',
            filterable : false,
            renderCell: (params) => ( <FinalRefillQty {...params?.row} />),
            customRenderCell: FinalRefillQty
        },
        { 
            field: 'lower_limit', 
            headerName: 'Lower Limit', 
            headerClassName: 'super-app-theme--header',
            filterable : true,
        },
        { 
            field: 'upper_limit', 
            headerName: 'Upper Limit', 
            headerClassName: 'super-app-theme--header',
            filterable : true,
        },
        { 
            field: 'totalPoQuantity', 
            headerName: 'Total Po Quantity', 
            headerClassName: 'super-app-theme--header',
            filterable : false,
            renderCell: (params) => ( <TotalPoQty {...params?.row} />),
            customRenderCell: TotalPoQty
        },
        { 
            field: 'lastPoNumber', 
            headerName: 'Last Po Number', 
            headerClassName: 'super-app-theme--header',
            filterable : false,
            renderCell: (params) => ( <LastPoNumberRenderCell {...params?.row} /> ),
            customRenderCell: LastPoDateRenderCell,
            // width: 200
        },
        { 
            field: 'lastPoDate', 
            headerName: 'Last Po Date', 
            headerClassName: 'super-app-theme--header',
            filterable : false,
            renderCell: (params) => ( <LastPoDateRenderCell {...params?.row} /> ),
            customRenderCell: LastPoDateRenderCell,
            width: 200
        },
        { 
            field: 'locationCode', 
            headerName: 'Location Code', 
            headerClassName: 'super-app-theme--header',
            filterable : true,
            type: 'enum',
            tableName: 'Locations',
            columnName: 'locationCode',
        },
        { 
            field: 'flagged', 
            headerName: 'Flagged', 
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (params) => ( <FlaggedRenderCell {...params?.row} />),
            customRenderCell: FlaggedRenderCell
        },
        { 
            field: 'update', 
            headerName: 'Update', 
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (params) => ( <UpdateButtonRenderCell {...params?.row} /> ),
            customRenderCell: UpdateButtonRenderCell
        },
        { 
            field: 'portfolio_toggle', 
            headerName: 'Portfolio', 
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (params) => ( <PortfolioToggleRenderCell {...params?.row} /> ),
            customRenderCell: PortfolioToggleRenderCell
        },
        { 
            field: 'stock_strategy', 
            headerName: 'Stock Strategy', 
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (params) => ( <StockStrategyRenderCell {...params?.row} /> ),
            customRenderCell: StockStrategyRenderCell
        },
        { 
            field: 'showroom_display', 
            headerName: 'Showroom Display', 
            headerClassName: 'super-app-theme--header',
            filterable: false,
            renderCell: (params) => ( <ShowRoomDisplayRenderCell {...params?.row} /> ),
            customRenderCell: ShowRoomDisplayRenderCell
        },
    ];

    const generateReport = async () => {
        const newReportName = 'Low Inventory Tracker';
        const fileName = `${newReportName}_Report_${currentTimeStamp()}`;
        const params = {
            uploadFileName : fileName,
            reportName : newReportName,
            // locationCode,
            flagged,
            text
        };

        await axiosInstance
            .get(`/analytics/reports/lowInventory`, { params })
            .then((res) => {
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: res?.data?.message, type: "success" }
                });
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
    };

    const closeDialog = () => {
        setUpdate(null);
    }

    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

            {update ? 
                <UpdateLowerAndUpperLimit data={update} closeDialog={closeDialog} setReRender={setReRender} /> 
            : null}

                <div 
                    style={{ 
                        margin:'8px 0px', display:'flex', alignItems:'center', 
                        justifyContent:'end', gap:'8px',flexWrap:'wrap' 
                    }}
                >
                    <div>
                        <Button variant='contained' onClick={generateReport}>Export Report</Button>
                    </div>

                    <ImportFileComponent
                        endpoint={'/inventory/bulkUpdateLowerAndUpperLimit'}
                        // serviceName={services?.pincodeSetting}
                        type="update lower & upper limit of inventories"
                        templateDownloadEndpoint={"/templates/bulkUpdateLowerAndUpperLimit"}
                        setReRender={setReRender}
                        // setData={setCsvResponse}
                        text={'Bulk update lower & upper limit'}
                    />
                </div>

                <div style={{
                    margin: '8px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: '24px'
                }}>
                    <FormControlLabel 
                        control={
                            <Switch 
                                checked={flagged} 
                                onChange={(e) => { setFlagged(e.target.checked) }} 
                                color='error' 
                            />
                        } label="Flagged" labelPlacement='start'
                    />

                    {/* <div style={{width: '200px'}}>
                        <Autocomplete
                            size='small'
                            disablePortal
                            id="locationCode"
                            name="locationCode"
                            options={locations}
                            renderInput={(params) => <TextField 
                                {...params} label="Location Code"
                                onBlur={(event) => {setLocationCode(event.target.value)}}
                            />}
                        />
                    </div> */}
                </div>
                
            
            <div>
                <Table 
                    columns={columns} 
                    dataPosition={"inventories"} 
                    endpoint={`/inventory/getInventories`} 
                    rowId={'inventory_id'}
                    checkBoxSelection={false}
                    tabName={'Low Inventory Tracker'}
                    setData={setData}
                    data={data}
                    createdByFilter={false}
                    isReactDispatch={true}
                    flagged={flagged}
                    // locationCode={locationCode}
                    reRender={reRender}
                    setText={setText}
                    version="version-2"
                    mobileResponsive={true}
                    exportEndpoint={'/exports/fetchLowInventoryTracker'}
                    exportFileName={`Low Inventory Tracker - ${new Date()}`}
                />
            </div>
        </Fragment>
    )
}
export default LowInventoryTracker;