import { useState, Fragment } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { Button, Typography } from "@mui/material";
import RiderPopup from './riderPopup';
import RiderUpdatePopup from './updateRider';
import AddIcon from '@mui/icons-material/Add';
import Classes from '../customers/css/customer.module.css';
import FormDialog from '../../../components/FormDialog/FormDialog';
import { useSelector } from 'react-redux';
import { setRiders } from '../../../store/deliveryRidersSlice';
import SnackBar from '../../../components/SnackBar';
import { Chip } from '@mui/material';
import Table from "../../../components/table/table";

const DeliveryRiders = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [updatePopup, setUpdatePopup] = useState(false);
    const [riderId, setRiderId] = useState(null);
    const [riderName, setRiderName] = useState(null);
    const [reRender, setReRender] = useState(false);
    const [locationCode, setLocationCode] = useState(null);
    const [email, setEmail] = useState(null);
    const [phoneNo,setPhoneNo] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const deliveryRiders = useSelector((state) => state?.deliveryRiders);

    const showUpdatePopup = (id, name, locationCode, email, phone) => {
        setRiderId(id)
        setRiderName(name);
        setLocationCode(locationCode);
        setEmail(email);
        setPhoneNo(phone);
        setUpdatePopup(true)
    }

    const hideUpdatePopup = () => {
        setUpdatePopup(false)
        setRiderId(null)
        setRiderName(null);
        setPhoneNo(null);
    }

    const UpdateBtnRenderCell = (row) => {
        return (
            <Button 
                variant='contained' 
                size="small" 
                onClick={(e) => showUpdatePopup(
                    row?.deliveryBoyId, row?.deliveryRider, row?.locationCode, row?.email, row?.phone
                )}
            >Update</Button>
        )
    }

    const DeleteRenderCell = (row) => {
        return (
            <FormDialog
                dialogTitle="Confirm Delivery Rider Deletion?"
                dialogActionType="confirmation"
                openDialogButtonName="Delete"
                submitDialogButtonName="Delete"
                onConfirmation={() => handleDelete(row?.deliveryBoyId)}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    This action would permanently delete Delivery Rider?
                </Typography>
            </FormDialog>
        )
    }

    const columns = [
        {
            field: `deliveryBoyId`,
            headerName: `ID`,
            flex: 0.4,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `deliveryRider`,
            headerName: `RIDER`,
            flex: 2,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `phone`,
            headerName: `PHONE`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `email`,
            headerName: `EMAIL`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
        },
        {
            field: `locationCode`,
            headerName: `Location Code`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: true,
            renderCell: (params) => (
                params?.value ? <Chip label={params.value} /> : 'N/A'
            )
        },
        {
            field: `createdAt`,
            headerName: `Created At`,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'left',
            align: 'left',
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: 'Update',
            headerName: 'Update',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <UpdateBtnRenderCell {...params?.row} />),
            customRenderCell: UpdateBtnRenderCell
        },
        {
            field: 'Delete',
            headerName: 'Delete',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (params) => ( <DeleteRenderCell {...params?.row} />),
            customRenderCell: DeleteRenderCell
        }
    ];

    const hidePopup = () => {
        setShowPopup(false);
    }

    const handleDelete = async (id) => {
        await axiosInstance
            .delete(`pgOrder/admin/deleteDeliveryRider/${id}`)
            .then((res) => {
                const successMessage = 'Delivery Rider Deleted Successfully';
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setReRender(!reRender);
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    return (
        <Fragment>
            <div style={{ display:'flex',width:'99%',justifyContent:'end',flexWrap:'wrap',marginTop:-25, marginBottom:10 }}>
                <Button startIcon={<AddIcon />} variant="contained" onClick={() => setShowPopup(true)}>Add</Button>
            </div>

            <div>
                <Table
                    columns={columns}
                    dataPosition={"fetchedRiders"}
                    endpoint={`/pgOrder/admin/deliveryRiders`}
                    rowId={'deliveryBoyId'}
                    checkBoxSelection={false}
                    version="version-1"
                    reRender={reRender}
                    service={'deliveryRiders'}
                    tabName={'Delivery Riders'}
                    setData={setRiders}
                    data={deliveryRiders}
                    mobileResponsive={true}
                    exportEndpoint={'/exports/fetchDeliveryRiders'}
                    exportFileName={`Riders - ${new Date()}`}
                />
            </div>

            <div className={showPopup ? Classes.UpdateSalesDialog : undefined}>
                <RiderPopup
                    showPopup={showPopup}
                    hidePopup={hidePopup}
                    reRender={reRender}
                    setReRender={setReRender}
                />
            </div>

            <div className={updatePopup ? Classes.UpdateSalesDialog : undefined}>
                <RiderUpdatePopup
                    showPopup={updatePopup}
                    hidePopup={hideUpdatePopup}
                    reRender={reRender}
                    setReRender={setReRender}
                    riderId={riderId}
                    riderName={riderName}
                    setRiderName={setRiderName}
                    locationCode={locationCode}
                    setLocationCode={setLocationCode}
                    email={email}
                    setEmail={setEmail}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                />
            </div>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    );
};

export default DeliveryRiders;