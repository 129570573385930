import React, { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Classes from './css/Modal.module.css';
import { faXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../../utility/axios-instance';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from "react-router-dom";
import { Chip, Tooltip } from '@mui/material';

const Modal = (props) => {
	const [searchProduct, setSearchProduct] = useState([]);
	const [nextPage, setNextPage] = useState([]);
	const [maxPage,setMaxPage] = useState();

	const {
		productArr,
		variant,
		setVariant,
		toggle,
		setToggle,
		search,
		setSearch,
		show,
		setProductNew,
		productNew,
		productIdArr,
		setProductIdArr,
		district,
	} = props;

	let countProduct = { variant };
	let countVariant = countProduct.variant;

	document.addEventListener('mouseup', function (e) {
		let container = document.getElementById('modalOutline');
		if (container !== null) {
			if (!container.contains(e.target)) {
				container.style.border = '1px solid rgb(206, 212, 218)';
			}
		}
	});

	const AddElemFromArr = (product, districtForPrices, isParentProduct = false, isVirtualProduct = false) => {
		if (productArr.length === 0) {
			setProductNew((productNew) => [
				...productNew,
				{ variantId: product, quantity: 0, PCS: 0, districtForPrices: districtForPrices, isParentProduct, isVirtualProduct },
			]);
			document.getElementById('btn').style.backgroundColor = '#008060';
			document.getElementById('btn').style.color = '#fff';
			document.getElementById('variant').style.color = '#000000';
		} else {
			document.getElementById('btn').style.backgroundColor = '#008060';
			document.getElementById('btn').style.color = '#fff';
			document.getElementById('variant').style.color = '#000000';
			setProductNew((productNew) => [
				...productNew,
				{ variantId: product, quantity: 0, PCS: 0, districtForPrices: districtForPrices, isParentProduct, isVirtualProduct },
			]);
		}
	};

	const remElemFromArr = (product) => {
		setProductNew((productNew) =>
			productNew.filter((ele) => ele.variantId !== product)
		);

		if (countVariant === 0) {
			document.getElementById('btn').style.backgroundColor = '';
			document.getElementById('btn').style.color = '';
			document.getElementById('variant').style.color = '';
		}
	};

	const handleChange = async (event, parentId, childId, districtForPrices, childNodes, variantIsParent, variantIsVirtual) => {
		if (event.checked) {
			if (productIdArr.length === 0) {
				setProductIdArr([parentId])
			} else {
				if (!productIdArr.includes(parentId)) {
					const array = productIdArr;
					array.push(parentId);
					setProductIdArr(array)
				}
			}

			if (event.className === 'parentNode') {
				const parentList = document.getElementById(parentId);
				const ListChildren = parentList.children;
				for (let i of ListChildren) {
					const productVariant = childNodes?.find((variant) => variant?.variantId === i?.id)
					if (i.id) {
						const child =
							i.firstElementChild.firstElementChild.firstElementChild;
						if (!child.checked) {
							child.checked = true;
							countVariant += 1;
							AddElemFromArr(i.id, productVariant?.districtForPrices, productVariant?.isParentProduct, productVariant?.isVirtualProduct);
						}
					}
				}
				setVariant(countVariant);
			} else {
				if (childId) {
					countVariant += 1;
					setVariant(countVariant);
					const parent =
						document.getElementById(parentId).firstElementChild
							.firstElementChild.firstElementChild;
					if (productArr.length > 0) {
						if (!parent.checked) {
							parent.checked = true;
						}
						AddElemFromArr(childId, districtForPrices, variantIsParent, variantIsVirtual);
						return;
					}
					parent.checked = true;
					AddElemFromArr(childId, districtForPrices, variantIsParent, variantIsVirtual);
					return;
				}
				setVariant(countVariant);
			}
		} else {
			if (event.className === 'parentNode') {
				if (productIdArr.length !== 0) {
					setProductIdArr((product) =>
						product.filter((ele) => ele !== parentId)
					);
				}
				const parentList = document.getElementById(parentId);
				const ListChildren = parentList.children;
				for (let i of ListChildren) {
					if (i.id) {
						if (countVariant !== 0) {
							countVariant -= 1;
							const child =
								i.firstElementChild.firstElementChild.firstElementChild;
							if (child.checked) {
								child.checked = false;
								remElemFromArr(i.id);
							}
						}
					}
				}
				setVariant(countVariant);
			} else {
				const parentList = document.getElementById(parentId);
				const ListChildren = parentList.children;
				const parent =
					document.getElementById(parentId).firstElementChild.firstElementChild
						.firstElementChild;
				for (let i of ListChildren) {
					if (i.id) {
						const child = document.getElementById(i.id).firstElementChild
							.firstElementChild.firstElementChild;
						if (child.checked) {
							if (countVariant !== 0) {
								countVariant -= 1;
							}
							remElemFromArr(event.id);
							parent.checked = true;
							setVariant(countVariant);
							return;
						}
					}
				}
				if (productIdArr.length !== 0) {
					setProductIdArr((productId) =>
						productId.filter((ele) => ele !== parentId)
					);
				}
				parent.checked = false;
				if (countVariant !== 0) {
					countVariant -= 1;
				}
				setVariant(countVariant);
				remElemFromArr(event.id);
			}
		}
	};

	const fetchProducts = (product) => {
		document.getElementById('modalOutline').style.border = '2px solid #458fff';
		setToggle(true);
		if (product === '') {
			setNextPage([1])
		}

		setSearch(product);
	};

	const [searchLoader, setSearchLoader] = useState(false);

	useEffect(() => {
		if (toggle) {
			setSearchLoader(true);
			let timeoutId = setTimeout(() => {
				axiosInstance
					.get(`product/productsFromPgDb`, {
						params: {
							page: 1,
							text: search,
							district: district,
							// apiHitFrom: 'salesOrder' // commented sortby inv. controlled by this param to speed up product search
						}
					})
					.then((res) => {
						if (res?.data?.data?.maxPage > 1) {
							setNextPage([2]);
						}
						setMaxPage(res?.data?.data?.maxPage)
						const productsFetched = res?.data?.data?.productVariant
						productsFetched.forEach((productFetched)=>{
							productFetched.childNode = productFetched.childNode.map((childNode) => {
								return {
									...childNode,
									page: 1,
								};
							})							
						})
						setSearchProduct(productsFetched);
					}).catch(error => {
						console.log('Error:', error.message);
						alert(error.message || 'An error occured while fetching products')
					}).finally(() => {
						setSearchLoader(false);
					});
			}, 500)
			return () => {
				clearTimeout(timeoutId);
			}
		}
	}, [search, toggle]);

	const fetchMoreData = () => {
		if (maxPage >= nextPage[0]) {
			setTimeout(() => {
				axiosInstance
					.get(`product/productsFromPgDb`, {
						params: {
							page: nextPage[0],
							text: search,
							district: district,
							// apiHitFrom: 'salesOrder' // commented sortby inv. controlled by this param to speed up product search
						}
					})
					.then((res) => {
						if (res.data.data.maxPage >= res.data.data.page) {
							const page = res.data.data.page + 1
							setNextPage([page]);
						}
						const productsFetched = res?.data?.data?.productVariant
						
						setSearchProduct((prevSearchProduct) => {
							let existingSearchedProducts = [...prevSearchProduct]
							let productsToBeAppendedDirectly = []
							const existingSearchedProductsGroupNames = existingSearchedProducts.map((product) => product?.groupName)
							for (let productFetched of productsFetched) {
								// if the product fetched is already present in the existing searched products
								if (existingSearchedProductsGroupNames.includes(productFetched?.groupName)) {
									const existingSearchedProduct = existingSearchedProducts.find((product) => product?.groupName === productFetched?.groupName)
									let existingSearchedProductChildNodeIds = existingSearchedProduct.childNode.map((childNode) => childNode?.variantId)
									const productFetchedChildNodeIds = productFetched.childNode.map((childNode) => childNode?.variantId)
									const childNodeIdsToBeMerged = productFetchedChildNodeIds.filter((childNodeId) => !existingSearchedProductChildNodeIds.includes(childNodeId))
									productFetched.childNode = productFetched.childNode.filter((childNode) => childNodeIdsToBeMerged.includes(childNode?.variantId))
									productFetched.childNode = productFetched.childNode.map((childNode) => {
										return {
											...childNode,
											page: nextPage[0],
										};
									})
									existingSearchedProduct.childNode = [...existingSearchedProduct.childNode, ...productFetched.childNode]
								} else {
									// if the product fetched is not present in the existing searched products
									const groupNameOfProductToBeDirectlyAppended = productFetched?.groupName
									const groupNameAlreadyExistInToBeDirectlyAppended = productsToBeAppendedDirectly.some((productToBeAppendedDirectly) => productToBeAppendedDirectly?.groupName === groupNameOfProductToBeDirectlyAppended)
									if (!groupNameAlreadyExistInToBeDirectlyAppended) {
										productFetched.childNode = productFetched.childNode.map((childNode) => {
											return {
												...childNode,
												page: nextPage[0],
											};
										})
										productsToBeAppendedDirectly.push(productFetched)
									}
								}
							}
							return [...existingSearchedProducts, ...productsToBeAppendedDirectly]
						});
					})
					.catch((error) => {
						console.log('Error:', error.message);
						alert(error.message || 'An error occured while fetching more products')
					});
			}, 500);
		}
	};
	return (
		<Fragment>
			{show && (
				<div className={Classes.modal}>
					<div className={Classes.BoxContent} style={{paddingBottom:10}}>
						<p>All Products</p>
						<div>
							<FontAwesomeIcon
								icon={faXmark}
								className={Classes.ClosePopUpIcon}
								onClick={props.onHide}
							/>
						</div>
					</div>

					<div className={Classes.Box}>
						<div className={[Classes.BoxContent,Classes.Border].join(' ')}>
							<div id="modalOutline" className={Classes.SearchInputBox}>
								<div>
									<FontAwesomeIcon
										icon={faMagnifyingGlass}
										className={Classes.SearchIcon}
									/>
								</div>

								<div style={{ width: '100%', position: 'relative' }}>
									<input
										type="text"
										id="search"
										autoFocus={true}
										autoComplete="off"
										className={props.styles.searchField}
										placeholder="Search products"
										value={search}
										onChange={(e) => fetchProducts(e.target.value)}
									/>
									{searchLoader ? 
										<div style={{position:'absolute',top:4,right:10}}>
											<span className={Classes.SearchLoader}></span>
										</div> : null
									}
								</div>
							</div>
						</div>
					</div>

					{searchProduct?.length !== 0  && (
						<div className={Classes.BoxContainer} id="ScrollableContainer">
							<InfiniteScroll
								dataLength={searchProduct?.length}
								next={fetchMoreData}
								hasMore={nextPage[0] <= maxPage ? true : false}
								loader={
									<div>{nextPage[0] < maxPage ? <h6>Loading...</h6> : ''}</div>
								}
								scrollableTarget="ScrollableContainer"
								endMessage={
									<p style={{ textAlign: "center" }}>
										Yay! You have seen it all
									</p>
								}
							>
								
								{searchProduct?.map((product,index) => (
									<div id={product?.productId} key={index}>
										<div className={Classes.Boxes}>
											<div>
												<input
													type="checkbox"
													className="parentNode"
													key={productIdArr.length !== 0 && productIdArr.includes(product?.productId) ? true : false}
													defaultChecked={productIdArr.length !== 0 && productIdArr.includes(product?.productId) ? true : false}
													onChange={(e) => handleChange(e.target, product?.productId, undefined, undefined, product?.childNode)}
												/>
											</div>
											<div style={{display:'flex'}}>
												<img 
													src={product?.images ? product?.images : process.env.REACT_APP_IMAGE_COMMING_SOON}
													alt="icon"
													className={Classes.productImage}
												/>
												<p style={{margin:'auto 0px'}} className={Classes.productContent}>
													{product?.groupName}
												</p>
											</div>
										</div>
										
										{product?.childNode?.map((variant) => (
											<div className={Classes.Child} key={variant?.variantId} id={variant?.variantId} style={{ padding: '0.3rem 2.3rem' }}>
												<div style={{ display: 'flex' }}>
													<div style={{margin:'auto 0px'}}>
														<input
															type="checkbox"
															className="childNode"
															id={variant?.variantId}
															key={productNew.length !== 0 && productNew.some(el => el.variantId === variant?.variantId) ? true : false}
															defaultChecked={productNew.length !== 0 && productNew.some(el => el.variantId === variant?.variantId) ? true : false}
															onChange={(e) => {
																handleChange(e.target, product?.productId, variant?.variantId, variant?.districtForPrices, undefined, variant?.isParentProduct, variant?.isVirtualProduct)
															}}
														/>
													</div>
													<div>
														<div style={{display:'flex'}}>	
															<p className={Classes.variantContent} style={{fontSize:14}}>{variant?.variable1Type} : {variant?.variable1Value}&nbsp;</p>
															{variant?.variable2Value && ( <p style={{fontSize:14}}>| {variant?.variable2Type} : {variant?.variable2Value}&nbsp;</p> )}
															{variant?.variable3Value && ( <p style={{fontSize:14}}>| {variant?.variable3Type} : {variant?.variable3Value}</p> )}
														</div>

														<div className={Classes.variantSkuBox} style={{fontSize:12}}>
															<p className={Classes.variantSku}>SKU:- </p>
															<Link target='_blank' to={`/products/${variant?.variantId}`}><p>{variant?.sku}</p></Link>
														</div>
														<div className={Classes.variantSkuBox} style={{fontSize:12}}>
															<p className={Classes.variantSku}>GST:- </p>
															<p>{variant?.gst}%</p>
														</div>

														<div className={Classes.fontSizeDisplay} style={{fontSize:12}}>
															<p style={{ marginLeft: '15px', marginRight: '5px' }} className={Classes.variantSku}>Available Qty:</p>
															{variant?.availableQuantity?.length > 0 && variant?.availableQuantity?.slice(0,5)?.map((inventory) => (
																<Chip label={`${inventory?.locationCode} : ${inventory?.availableQty}`} size='small' style={{ marginRight: 5, backgroundColor: "#ff6200", color: "#fff", height: 'fit-content' }} />
															))}
															{variant?.availableQuantity?.length > 5 ? (
																<Tooltip title={
																	<div>
																		{variant?.availableQuantity?.slice(5)?.map((inventory, index) => (
																			<p>{`${inventory?.locationCode} : ${inventory?.availableQty}`}, </p>
																		))}
																	</div>
																} arrow>
																	<Chip size="small" label={`+ ${variant?.availableQuantity?.length - 5} `} style={{ cursor: "pointer", opacity:'0.8',height:'21px'}} />
																</Tooltip>
															) : null}
														</div>
														<div className={Classes.fontSizeDisplay} style={{ marginTop: '5px',fontSize:12 }}>
															<p style={{ marginLeft: '15px', marginRight: '5px' }} className={Classes.variantSku}>Price:</p>
															<Chip variant="outlined" label={`${variant?.districtForPrices} : ₹ ${variant?.price} INR`} size='small' style={{ marginRight: 5, backgroundColor: "#10243E", color: "#fff", height: 'fit-content' }} />
														</div>
														{variant?.isParentProduct && <div>

															<Chip variant="outlined" label={`Parent Product`} size='small'  color='secondary'/>
															{variant?.isVirtualProduct && <Chip variant="outlined" label={`Virtual Product`} size='small' color='secondary'  />}
														</div>}
													</div>
												</div>
												{
													variant.page && <div>
														Page: {variant?.page}
													</div>
												}
											</div>
										))}
									</div>
								))}
							</InfiniteScroll>
						</div>
					)}

					<div className={[Classes.Footer,Classes.Button].join(' ')} style={{width:'100%'}}>
						<div>
							<button id="variant">{variant} variants selected</button>
						</div>
						<div className={[Classes.TextAlignRight,Classes.Add].join(' ')}>
							<div>
								<button onClick={props.onHide} style={{ color:'black' }}>
									Cancel
								</button>
							</div>
							<div>
								<button id="btn" onClick={props.AddHide}>
									Add
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default Modal;
